import { breakpoints, device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'
import styled from 'styled-components'

export const Section = styled.section`
  h1, h2 {
    font-family: 'Citrina', Helvetica, sans-serif;
    color: ${grayscale[500]};
  }

  h1 {
    font-size: 28px;
    font-weight: 400;

    @media ${device.tablet} {
      font-size: 40px;
    }
    @media ${device.desktopLG} {
      font-size: 56px;
    }
    @media ${device.desktopXL} {
      font-size: 72px;

    }
  }

  position: relative;

  img {
    width: 312px;
    height: 312px;


    @media (min-width: ${breakpoints.md}){
      width: 253px;
      height: 253px;
    }

    @media (min-width: ${breakpoints.lg}){
      width: 295px;
      height: 295px;
    }

    @media (min-width: ${breakpoints.xl}){
      width: 456px;
      height: 456px;
    }
  }

  .breadcrumb{
    span{
      color: #6A6C72 !important;
    }
    a{
      font-weight: 600;
      color: #6A6C72 !important;
    }
  }

  .icon{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
  }
`
