import { breakpoints } from 'src/styles/breakpoints'
import styled from 'styled-components'
const SimplifyPaymentLg = 'https://central-imagens.bancointer.com.br/images-without-small-versions/dobra2-1024-768/image.webp'
const SimplifyPaymentMd = 'https://central-imagens.bancointer.com.br/images-without-small-versions/dobra2-1024-768/image.webp'
const SimplifyPaymentXl = 'https://central-imagens.bancointer.com.br/images-without-small-versions/dobra2-1440/image.webp'

export const Section = styled.section`
  h2 {
    font-family: 'Citrina', Helvetica, sans-serif;
  }
    background: #F5F6FA;
    min-height: 346px;

    @media (min-width: ${breakpoints.md}){
      background-image: url('${SimplifyPaymentMd}');
      background-size: contain;
      background-repeat: no-repeat;
      min-height: 337px;

    }

    @media (min-width: ${breakpoints.lg}){
      background-image: url(${SimplifyPaymentLg});
      background-size: contain;
      background-repeat: no-repeat;
      min-height: 431px;

    }
    @media (min-width: ${breakpoints.xl}){
      background-image: url(${SimplifyPaymentXl});
      background-size: contain;
      background-repeat: no-repeat;
      min-height: 622px;

    }
`
