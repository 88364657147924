import { breakpoints } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'
import styled from 'styled-components'

export const Wrapper = styled.section`
  h2 {
    font-family: 'Citrina', Helvetica, sans-serif;
  }
  background-color: ${grayscale[100]};
  padding-top: 40px;
  padding-bottom: 40px;

  @media (min-width: ${breakpoints.lg}){
    padding-top: 64px;
    padding-bottom: 64px;
  }

  @media (min-width: ${breakpoints.xl}){
    padding-top: 96px;
    padding-bottom: 96px;
  }

  hr {
    margin: 0;
  }

  .link-button {
    max-width: 456px;
  }

  .text-center {
    @media (min-width: ${breakpoints.md}){
      text-align: center;
    } 
  }
`

export const ListTitle = styled.p`
  margin-left: 18px;
  margin-bottom: 0;
  padding-top: 3px;
`

export const List = styled.div`
  margin: 27px 0;
`

export const Card = styled.div`
  padding: 25px 16px;
  border: 1px solid ${grayscale[200]};
  border-radius: 8px;
  background-color: white;
  width: 125px;
  height: 190px;
  margin: 0 8px 40px;

  @media (min-width: ${breakpoints.lg}){
    width: 160px;
    height: 210px;
    margin: 0 12px 40px;
    padding: 32px 26px;
  }

  @media (min-width: ${breakpoints.xl}){
    width: 205px;
  }
`
